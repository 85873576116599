import "index.css"

// Import all JavaScript & CSS files from src/_components
import components from "bridgetownComponents/**/*.{js,jsx,js.rb,css}"

console.info("Bridgetown is loaded!")


jQuery(document).ready(function () {
  jQuery('.button').click(function() {
      targetName = jQuery(this).attr('href');
      targetLocation = jQuery(targetName).offset().top - 63;
      jQuery('html, body').animate({scrollTop:targetLocation}, 'slow');
      return false;
  });
  jQuery('.main-carousel').flickity({
    // options
    pageDots: false,
    cellAlign: 'left',
    contain: true,
    wrapAround: true
  });
});
